// Swahili
export default{
    form: {
        startSurveyButton: "Tuanze",
        nextButton: "Ifuatayo",
        submitAnswerButton: "Tuma Jibu",
        continueButton: "Endelea",
        finishButton: "Maliza",
        headerPercentageCompletion: 'imekamilika',
        headerQuestionsCompleted: 'maswali',
        headerDefaultErrorMessage: 'Oops! Kitu kimeenda vibaya.',
        brandingPoweredBy: 'Inaendeshwa na',
        brandingPromoWhySurvey: 'Kwa nini ufanye uchunguzi wakati unaweza kutumia <b>{product}</b>?',
        brandingPromoGetStarted: 'Anza leo!',
        submissionPending: "Inatuma voiceform yako. Tafadhali usifunge dirisha la kivinjari.",
        submissionCanCloseWindow: "Voiceform yako imetumwa kwa mafanikio. Unaweza kufunga dirisha sasa 👍",
        mediaAttachmentOverlayCloseButton: "Funga",
        expiredFormHeader: "Asante kwa kuja!",
        expiredFormMessage: "Inaonekana uchunguzi huu umekwisha muda wake. Tafadhali wasiliana na muundaji wa voiceform kumjulisha.",
        notPublishedFormHeader: "Voiceform haijachapishwa!",
        notPublishedFormMessage: "Inaonekana uchunguzi huu haujachapishwa. Tafadhali wasiliana na muundaji wa voiceform kumjulisha.",
        notPublishedFormRetryButton: "Jaribu Tena",
        errorFormHeader: "Oops!",
        errorFormMessage: "Kitu kinaweza kuwa kimeenda vibaya. Tafadhali jaribu tena baadaye.",
        errorFormRetryButton: "Jaribu Tena",
        emptyFormHeader: "Asante kwa kuja!",
        emptyFormMessage: "Oops! Inaonekana fomu hii haina maswali au sehemu za kujaza kwa sasa. Tunaomba radhi kwa usumbufu. Tafadhali wasiliana na muundaji wa fomu kumjulisha suala hili. Asante kwa kuelewa na kushirikiana kwako!",

        restoreFormHeader: "Inaonekana una fomu inayoendelea!",
        restoreFormMessage: "Je, ungependa kuendelea kutoka ulipoishia?",
        restoreFormButton: "Endelea Fomu",
        restoreFormButtonNew: "Anza Tena",

        audioPermissionHeader: "Ungependa kujibu kwa sauti?",
        audioPermissionMessage: "Kuzungumza kutafanya kujibu kuwa haraka na rahisi.",
        audioPermissionAcceptButton: "Ndio, nataka",
        audioPermissionDenyButton: "Hapana, asante",
        audioPermissionErrorHeader: "Mikrofoni haipatikani",
        audioPermissionErrorMessage: "Tafadhali angalia ruhusa za kivinjari chako ili kuwezesha ufikiaji wa mikrofoni.",
        audioPermissionErrorButton: "Sawa",
        audioRecorderFailedUpload: "Imeshindwa kupakia kurekodi",
        silenceDetectorError: "Hatuwezi kukusikia. Tafadhali angalia mipangilio ya mikrofoni yako. Kidokezo: Inaweza kuwa mikrofoni yako ya nje.",
        audioRecorderButtonRecord: "Anza Kurekodi",
        audioRecorderButtonRecordMore: "Rekodi Zaidi",
        voiceResponseSelectionMessage: "Chagua jinsi unavyotaka kujibu...",
        voiceResponseSelectionOrDivider: "au",
        voiceResponseTextInputPlaceholder: "Andika jibu",
        voiceResponseTextInputCharacterCounterMinMessage: "Tafadhali andika angalau herufi {min}",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} herufi (angalau {min} herufi)",
        voiceResponseTextInputCharacterValidationMessage: "{count} herufi (angalau {min} herufi)",

        // checkbox
        checkboxValidationTooFew: "Unapaswa kuchagua angalau chaguo {min}",
        checkboxValidationTooMany: "Unapaswa kuchagua zaidi ya chaguo {max}",
        checkboxNoneOfTheAboveOption: "Hakuna kati ya haya",
        checkboxInvalidAnswer: "Jibu si sahihi.",

        // datepicker
        datePickerPlaceholder: "Chagua tarehe",

        // dropdown
        // email
        emailLabel: "Barua pepe",
        emailInvalid: "Barua pepe si sahihi.",
        // file-upload
        fileUploadPluginNameCamera: "Kamera",
        fileUploadPluginNameCameraVideo: "Rekodi video",
        fileUploadPluginScreenCast: "Screen Cast",
        fileUploadVideoImportFilesDefault: 'Rekodi au pakia video kupitia:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Chagua chaguo la kurekodi video:',
        fileUploadVideoImportFilesNoCamera: 'Bofya kitufe kurekodi video ya skrini',
        fileUploadVideoImportFilesNoScreenCast: 'Bofya kitufe kurekodi video',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Hakuna chaguo la kurekodi video',

        // matrix
        matrixValidationMessage: "Jibu ni sahihi.",
        matrixRow: "Safu",
        matrixColumn: "Safu Wima",
        matrixRowRequired: "Safu {rowTitle} inahitajika.",
        matrixRadioGroup: "Kundi la Redio",
        matrixCheckboxGroup: "Kundi la Checkbox",
        matrixGroupRequired: "Kwa safu {rowTitle}. {type} {groupTitle} inahitajika.",
        matrixColumnRequired: "Kwa safu {rowTitle}. Safu wima {columnTitle} inahitajika.",
        matrixColumnInvalid: "Kwa safu {rowTitle}. Safu wima {columnTitle} si sahihi.",
        matrixRequired: "Inahitajika.",
        matrixNumericMustBeNumber: "Inapaswa kuwa namba.",
        matrixNumericMustBeGreaterThenMin: "Inapaswa kuwa kubwa kuliko {min}.",
        matrixNumericMustBeLessThenMax: "Inapaswa kuwa ndogo kuliko {max}.",
        matrixNumericMustBeInteger: "Inapaswa kuwa namba kamili.",
        matrixNumericInvalidNumber: "Namba si sahihi.",

        // name
        nameLabel: "Jina",
        nameInvalid: "Tafadhali taja jina la kwanza na la mwisho.",

        // nps
        npsNotLikely: "Si ya uwezekano kabisa",
        npsExtremelyLikely: "Inawezekana sana",

        // numeric input
        numericInputRequired: "Inahitajika.",
        numericInputMustBeNumber: "Inapaswa kuwa namba.",
        numericInputMustBeGreaterThenMin: "Inapaswa kuwa kubwa kuliko {min}.",
        numericInputMustBeLessThenMax: "Inapaswa kuwa ndogo kuliko {max}.",
        numericInputMustBeInteger: "Inapaswa kuwa namba kamili.",
        numericInputInvalidNumber: "Namba si sahihi.",
        numericInputPlaceholder: "Andika namba",

        // phone
        phoneInvalid: "Nambari ya simu si sahihi.",
        phoneCountrySelectorLabel: 'Msimbo wa nchi',
        phoneCountrySelectorError: 'Chagua nchi',
        phoneNumberLabel: 'Nambari ya simu',
        phoneExample: 'Mfano:',

        // boolean
        booleanYesLabel: "Ndio",
        booleanNoLabel: "Hapana",

        //questionStep
        questionStepAudioQuestionLabel: "Swali la Sauti",

        // errors
        invalidPhoneNumber: "{phone} ni nambari ya simu si sahihi.",
        invalidEmail: "{email} ni anwani ya barua pepe si sahihi.",
        questionIsRequired: "Swali linahitajika.",
        answerIsNotValid: "Jibu si sahihi.",
        unfinishedProbingDialogError: "Tafadhali maliza mazungumzo.",
        cannotResumePartialResponse: "Haiwezi kuendelea na jibu lililosimamishwa.",
        failedToSubmitForm: "Imeshindwa kutuma fomu. Tafadhali angalia muunganisho wako wa intaneti na ujaribu tena.",

        //language picker
        searchLanguage: "Tafuta lugha",
    }
}
